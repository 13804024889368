@tailwind base;

@layer base {
  @font-face {
    font-family: "Helvetica Neue";
    font-weight: 400;
    src: url("src/fonts/HelveticaNeue.woff2") format("woff2");
  }

  @font-face {
    font-family: "Helvetica Neue";
    font-weight: 700;
    src: url("src/fonts/HelveticaNeueBold.woff2") format("woff2");
  }

  @font-face {
    font-family: "Helvetica Neue";
    font-weight: 300;
    src: url("src/fonts/HelveticaNeueLight.woff2") format("woff2");
  }

  html {
    @apply text-primaryText;
  }
  th {
    @apply text-[0.95em] font-normal text-secondaryText;
  }
}

@tailwind components;
@tailwind utilities;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  @apply m-0;
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-radio {
  @apply appearance-none w-4 h-4 bg-clip-content p-[2px] rounded-full border-2 border-brand checked:bg-brand;
}
