/** 
* This custom style handles styling the Calender Component
* Note: We do a complete replacement and do not use the styles exported from react-date-picker
*/
.react-calendar {
  @apply max-w-full bg-white border rounded-md w-80;
}

.react-calendar--doubleView {
  @apply w-[700px];
}

.react-calendar--doubleView .react-calendar__viewContainer {
  @apply flex -m-2;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  @apply w-[50%] m-2;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  @apply box-border;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  text-decoration: none;
}

.react-calendar button {
  @apply m-0 border-0;
  outline: none;
}

.react-calendar button:enabled:hover {
  @apply cursor-pointer;
}

.react-calendar__navigation {
  @apply flex h-10;
}

.react-calendar__navigation button {
  @apply min-w-[40px];
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f5f6f8 !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f5f6f8 !important;
}

.react-calendar__navigation__label {
  @apply font-medium;
}

.react-calendar__month-view__weekdays {
  @apply text-sm font-medium text-center;
}

.react-calendar__month-view__days {
  @apply text-[0.9em] font-normal text-secondaryText;
}

.react-calendar__month-view__weekdays__weekday {
  @apply p-2;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  @apply flex items-center justify-center text-sm font-semibold;
}

.react-calendar__month-view__days__day {
  @apply rounded-full;
}

.react-calendar__month-view__days__day--weekend {
  @apply text-negative;
}

.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-name;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  @apply px-2 py-8;
}

.react-calendar__tile {
  @apply w-full p-[0.8em] text-center;
  background: none;
}

.react-calendar__tile:disabled {
  background-color: #f5f6f8 !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #f5f6f8 !important;
}

.react-calendar__tile--hasActive {
  background-color: #fc8a00 !important;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: #dd7901 !important;
}

.react-calendar__tile--active {
  background-color: #fc8a00 !important;
  color: white !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #dd7901 !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f5f6f8 !important;
}
