/** 
* This custom style handles styling the Calender Input Component
* Note: We do a complete replacement and do not use the styles exported from react-date-picker
*/
.react-date-picker {
  @apply relative inline-flex text-base rounded-md w-full p-1.5 border;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  @apply box-border;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.react-date-picker--disabled {
  @apply opacity-80 bg-background text-name;
}

.react-date-picker__wrapper {
  @apply flex grow shrink-0;
}

.react-date-picker__inputGroup {
  @apply min-w-[calc((4px_*_3)_+_0.54em_*_8_+_0.217em_*_2)];
  @apply box-content grow;
}

.react-date-picker__inputGroup__divider {
  @apply whitespace-pre;
}

.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  @apply inline-block;
}

.react-date-picker__inputGroup__input {
  @apply box-content text-current border-0;
  @apply min-w-[0.55em] px-[1px] focus:outline-none focus:ring-0 focus:ring-offset-1 focus:ring-offset-uiBorder focus:ring-uiBorder rounded-md relative;
  background: none;
  font: inherit;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  @apply appearance-none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 255, 255, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  @apply ml-[-0.54em] pl-[calc(1px_+_0.54em)];
}

.react-date-picker__button {
  @apply px-1;
  background: transparent;
}

.react-date-picker__button:enabled {
  @apply cursor-pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  @apply stroke-name;
}

.react-date-picker__button svg {
  display: inherit;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  @apply opacity-80 bg-background text-name;
}

.react-date-picker__calendar-button svg {
  @apply stroke-name;
}

.react-date-picker__calendar {
  @apply max-w-[100vh] z-50 w-80;
}

.react-date-picker__calendar--closed {
  @apply hidden;
}

.react-date-picker__calendar .react-calendar {
  @apply border-[thin];
}
